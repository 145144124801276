import React, { useState } from 'react'
import { SIDE_MENU_OPTIONS } from 'redesign/components/templates/SideMenu/constants'
import { MenuOptions } from 'redesign/components/templates/SideMenu/types'
import { getSideMenuConfiguration } from 'redesign/components/templates/SideMenu/utils'
import SideMenuContext from './context'
import type { SideMenuProviderProps } from './types'

const SideMenuProvider: React.FC<SideMenuProviderProps> = ({ children, onClose, open, parentPage }) => {
  const [page, setPage] = useState<MenuOptions>(SIDE_MENU_OPTIONS.mainMenu)

  const pages = getSideMenuConfiguration(parentPage)

  return (
    <SideMenuContext.Provider value={{ page, setPage, pages, open, onClose, parentPage }}>
      {children}
    </SideMenuContext.Provider>
  )
}

export default SideMenuProvider
