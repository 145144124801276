import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'
import { preProcessShifts } from 'components/ShiftsList/components/ShiftsWeeklyCalendar/utils'
import { getTaskIds, hasAcknowledgedShiftUpdate } from 'components/ShiftsList/components/utils'
import useShiftDaysReducer from 'components/ShiftsList/reducer'
import { DASHBOARD_NEXT_SHIFT_DATE, defaultDailyShiftDates } from 'constants/shift'
import { startOfWeek, endOfWeek } from 'date-fns'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import usePSN from 'hooks/usePushNotifications'
import useSessionContext from 'hooks/useSessionContext'
import useShifts from 'hooks/useShifts'
import useShiftsContext from 'hooks/useShiftsContext'
import useStateBackLink from 'hooks/useStateBackLink'
import useTutorials from 'hooks/useTutorials'
import { sortBy } from 'lodash/fp'
import useShiftUpdates from 'pages/Shifts/useShiftUpdates'
import useSideMenuData from 'pages/Shifts/useSideMenuData'
import { useHistory } from 'react-router'
import { useAppStatesContext } from 'services/AppStates'
import routes from 'services/RoutesProvider/routes'
import { modules } from 'shared/constants'
import { personsOnlyTenant } from 'shared/queries'
import type { PersonT, PlannedShiftT } from 'types'
import { isNativeNoOverride } from 'utils/platform'
import { PushNotifications } from '@capacitor/push-notifications'
import { IonContent } from '@ionic/core/components/ion-content'
import SwipeableContext from './context'
import type { SelectedTeamDetails } from './types'

const SwipeableProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [loadingGoToDay, setLoadingGoToDay] = useState(false)
  const [selectedTeamData, setSelectedTeamData] = useState<SelectedTeamDetails>()

  const onSelectTeamData = useCallback((selectedTeamDetails: SelectedTeamDetails) => {
    setSelectedTeamData(selectedTeamDetails)
    const ionContent = document.getElementById('ionContent') as IonContent
    if (ionContent) {
      ionContent.scrollToTop()
    }
  }, [])

  const [personsWithTenant, setPersonsWithTenant] = useState<PersonT[]>()
  const [shifts, setShifts] = useState<{
    [x: string]: PlannedShiftT[]
  }>({})
  const sideMenuData = useSideMenuData()
  const history = useHistory()
  const { personIds } = useSessionContext()
  const { pushActiveOnDevice } = usePSN({ refresh: false })
  const { beenShownBefore, isReady } = useTutorials()
  const { start: startContext, end: endContext, setShiftsContext } = useShiftsContext()
  const { setBackLink } = useStateBackLink()
  const getShiftDates = useCallback(
    (start: string, end: string) => (start && end ? { start, end } : defaultDailyShiftDates),
    [],
  )
  const { get, clear } = useAppStatesContext()
  const shiftDates = getShiftDates(startContext, endContext)
  const currentDate = get(DASHBOARD_NEXT_SHIFT_DATE) ?? shiftDates.start

  const {
    state,
    handleIndexChange,
    handleIndexWeekChange,
    goToToday: reducerGoToToday,
    goToDay: reducerGoToDay,
    handleIndexMonthChange,
    toggleCalendarOpen,
    closeCalendar,
  } = useShiftDaysReducer(shiftDates, currentDate)

  const { start, end } = useMemo(() => {
    return {
      start: startOfWeek(state.currentDay, { weekStartsOn: 1 }).toISOString(),
      end: endOfWeek(state.currentDay, { weekStartsOn: 1 }).toISOString(),
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.weekIndex, state.monthIndex])

  const { shifts: newShifts, loading: loadingShiftsQuery } = useShifts({ dates: { start, end }, personIds })
  const taskIds = useMemo(() => getTaskIds(newShifts), [newShifts])
  const { tasks, loading: shiftUpdatesLoading } = useShiftUpdates({ taskIds })
  const canShowShifts = useCallback(
    (shift: PlannedShiftT) => (shiftUpdatesLoading ? false : hasAcknowledgedShiftUpdate(shift, tasks)),
    [shiftUpdatesLoading, tasks],
  )

  useEffect(() => {
    if (!loadingShiftsQuery) setShifts(preProcessShifts(newShifts))
  }, [loadingShiftsQuery, newShifts])

  const goToDay = useCallback(
    (date: Date) => {
      setLoadingGoToDay(true)
      reducerGoToDay(date)
    },
    [reducerGoToDay],
  )

  const goToToday = useCallback(() => {
    setLoadingGoToDay(true)
    reducerGoToToday()
  }, [reducerGoToToday])

  const finishLoadingGoToDay = useCallback(() => setLoadingGoToDay(false), [])

  const { loading: loadingPersons } = useJamesApolloQuery<{ findAllPersons: PersonT[] }>(personsOnlyTenant, {
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      const personsWithAccess = data.findAllPersons.filter((person) => {
        if (!person.tenant || !person.tenant?.name) return false
        const { tenant } = person

        return tenant?.modules?.includes(modules.shiftPlan)
      })

      setPersonsWithTenant(sortBy('', personsWithAccess) as PersonT[])
    },
  })

  useEffect(() => {
    return () => setShiftsContext({ start: '', end: '' })
  }, [setShiftsContext])

  useEffect(() => {
    if (isNativeNoOverride() && pushActiveOnDevice) {
      PushNotifications.removeAllDeliveredNotifications()
    }
  }, [pushActiveOnDevice])

  useEffect(() => {
    clear(DASHBOARD_NEXT_SHIFT_DATE)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const mainParent = document.getElementById('ionContent') as IonContent
    mainParent?.scrollToTop()
  }, [state.currentDay])

  useEffect(() => {
    if (!isReady) return
    if (!beenShownBefore('shiftplan')) {
      setBackLink(routes.shifts)
      history.push(`${routes.tutorial}/shiftplan`)
    }
  }, [history, isReady, beenShownBefore, setBackLink])

  const value = useMemo(
    () => ({
      personsWithTenant,
      closeCalendar,
      finishLoadingGoToDay,
      goToDay,
      goToToday,
      handleIndexChange,
      handleIndexWeekChange,
      handleIndexMonthChange,
      loading: loadingGoToDay || loadingPersons,
      selectedTeamData,
      onSelectTeamData,
      sideMenuData,
      state,
      toggleCalendarOpen,
      weeklyShifts: shifts,
      loadingWeeklyShifts: loadingShiftsQuery,
      canShowShifts,
    }),
    [
      canShowShifts,
      closeCalendar,
      finishLoadingGoToDay,
      goToDay,
      goToToday,
      handleIndexChange,
      handleIndexMonthChange,
      handleIndexWeekChange,
      loadingGoToDay,
      loadingPersons,
      loadingShiftsQuery,
      onSelectTeamData,
      personsWithTenant,
      selectedTeamData,
      shifts,
      sideMenuData,
      state,
      toggleCalendarOpen,
    ],
  )

  return <SwipeableContext.Provider value={value}>{children}</SwipeableContext.Provider>
}

export default SwipeableProvider
