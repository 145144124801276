import React from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import DatePicker from '../../DatePicker'
import { Container } from './styles'
import type { DateRangeFilterProps } from './types'
import { useDateRangeFilter } from './useDateRangeFilter'

const DateRangeFilter: React.FC<DateRangeFilterProps> = ({ configuration }) => {
  const { t } = useFavurTranslation()
  const {
    endDateOnChange,
    error,
    fromDateValue,
    maxDate,
    minDate,
    startDateOnChange,
    toDateValue,
  } = useDateRangeFilter({ configuration })

  return (
    <Container>
      <DatePicker
        testId="filter-date-range"
        error={error}
        label={t('filter.from_date.label')}
        defaultValue={fromDateValue}
        onChange={startDateOnChange}
        minDate={minDate}
        maxDate={maxDate}
        fullWidth
      />
      <DatePicker
        error={error}
        label={t('filter.to_date.label')}
        defaultValue={toDateValue}
        onChange={endDateOnChange}
        minDate={minDate}
        maxDate={maxDate}
        fullWidth
      />
    </Container>
  )
}

export default DateRangeFilter
