import React, { useMemo } from 'react'
import { useMediaQuery, useTheme } from '@mui/material'
import useFilterContext from 'components/Filter/context'
import type { PersonsT } from 'components/Filter/types'
import { FF_FAVUR_REDESIGN } from 'constants/featureFlags'
import useFeatureFlag from 'hooks/useFeatureFlag'
import FullScreenModal from 'redesign/components/organisms/FullScreenModal'
import FiltersBody from './FiltersBody'
import FiltersFooter from './FiltersFooter'
import FiltersHeaders from './FiltersHeaders'
import type { FiltersProps } from './types'

const Filters: React.FC<FiltersProps> = ({ rootId }) => {
  const {
    cancelPersonsList,
    closeFilterPage,
    getConfiguration,
    getPersonsListSelected,
    isFilterPageOpen,
    getCurrentSelectedPersons,
    confirmPersonsList,
    submitFilterPage,
    resetFilterPage,
    validateFilters,
  } = useFilterContext()
  const theme = useTheme()
  const redesignFF = useFeatureFlag(FF_FAVUR_REDESIGN) === true
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const configuration = useMemo(() => getConfiguration(), [getConfiguration])
  const personsListSelected = useMemo(() => getPersonsListSelected(), [getPersonsListSelected])
  const isPersonsListSelected = Boolean(personsListSelected)
  const personsOption = useMemo(
    () =>
      personsListSelected
        ? (configuration.find((option) => option.name === personsListSelected) as PersonsT)
        : undefined,
    [configuration, personsListSelected],
  )

  const onSubmit = () => {
    if (isPersonsListSelected && personsOption) {
      confirmPersonsList(personsOption.name, getCurrentSelectedPersons())
    } else {
      submitFilterPage()
    }
  }

  return (
    <FullScreenModal
      disableDrag
      noPadding
      isSmallScreen={isSmallScreen}
      rootId={rootId}
      onClose={closeFilterPage}
      isOpen={isFilterPageOpen()}
      header={
        <FiltersHeaders
          isPersonsFilter={isPersonsListSelected}
          onClosePersonsFilters={() => cancelPersonsList(personsOption?.name ?? '')}
          onClose={closeFilterPage}
          onReset={() => resetFilterPage({ isRedesign: redesignFF })}
          isSmallScreen={isSmallScreen}
        />
      }
      footer={
        <FiltersFooter
          isPersonsFilter={isPersonsListSelected}
          hasSelectedPersons={Boolean(getCurrentSelectedPersons().length)}
          onClick={onSubmit}
          validFilters={validateFilters()}
        />
      }
    >
      <FiltersBody />
    </FullScreenModal>
  )
}

export default Filters
