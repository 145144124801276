import React, { useCallback } from 'react'
import { IconButton, useTheme } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { taskBarStates } from 'pages/AbsencePlanner/components/TaskBar/types'
import useAbsencePlannerStateContext from 'pages/AbsencePlanner/contexts/AbsencePlannerStateContext'
import { useTaskBarStateDispatchContext } from 'pages/AbsencePlanner/contexts/TaskBarStateContext'
import { MoreVertical } from 'redesign/components/atoms/Icon'
import HeaderLabel from 'redesign/components/molecules/HeaderLabel'
import Header from '../../Header'

const AbsencePlannerHeader: React.FC = () => {
  const { t } = useFavurTranslation()
  const theme = useTheme()
  const setTaskBarState = useTaskBarStateDispatchContext()
  const onMenuClick = useCallback(() => setTaskBarState(taskBarStates.filters), [setTaskBarState])
  const { isSmallScreen } = useAbsencePlannerStateContext()

  return (
    <Header
      middleContent={<HeaderLabel mainText={t('page.absencePlanner.title')} />}
      endContent={
        isSmallScreen && (
          <IconButton onClick={onMenuClick} size="medium">
            <MoreVertical color={theme.palette.primary.main} />
          </IconButton>
        )
      }
    />
  )
}

export default AbsencePlannerHeader
