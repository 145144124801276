import { Dialog, DialogContent, css, styled } from '@mui/material'

export const MuiDialog = styled(Dialog, {
  shouldForwardProp: (prop) => prop !== 'width' && prop !== 'height' && prop !== 'fixedHeight',
})<{ height: number; width: number; fixedHeight?: boolean }>`
  z-index: 800;
  & > * > * {
    min-width: ${({ theme, width }) => theme.spacing(width)};
    width: ${({ theme, width }) => theme.spacing(width)};
     ${({ fixedHeight, theme, height }) =>
       fixedHeight &&
       css`
         height: ${theme.spacing(height)};
       `}
`

export const MuiDialogContent = styled(DialogContent, {
  shouldForwardProp: (prop) => prop !== 'noPadding',
})<{ noPadding?: boolean }>`
  display: grid;
  grid-template-rows: auto auto 1fr;
  ${({ noPadding }) =>
    noPadding &&
    css`
      padding: 0;
    `}
`
