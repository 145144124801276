import { styled } from '@mui/material'

export const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isSideMenuOpen' && prop !== 'hasNavbar',
})<{
  isSideMenuOpen: boolean
  hasNavbar?: boolean
}>`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${({ isSideMenuOpen, theme }) =>
    isSideMenuOpen ? theme.palette.common.backgroundSideMenu : theme.palette.background.paper};
  padding-top: env(safe-area-inset-top);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);

  ${({ hasNavbar }) =>
    !hasNavbar &&
    `
      padding-bottom: env(safe-area-inset-bottom);

    `}
`
