import {
  BottomNavigation as MuiBottomNavigation,
  BottomNavigationAction as MuiBottomNavigationAction,
  styled,
} from '@mui/material'

export const Container = styled('div')`
  z-index: 1;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  padding-top: ${({ theme }) => theme.spacing(1.5)};
  padding-bottom: ${({ theme }) => `calc(${theme.spacing(1.5)} + env(safe-area-inset-bottom))`};
  box-shadow: ${({ theme }) => theme.spacing(0, 0.5, 2)} rgba(20, 26, 51, 0.06),
    ${({ theme }) => theme.spacing(0, 0.125, 1)} rgba(20, 24, 51, 0.04);
  background-color: ${({ theme }) => theme.palette.common.white};
`

export const BottomNavigation = styled(MuiBottomNavigation)`
  width: 100%;
  max-width: ${({ theme }) => theme.spacing(75)};
  padding: ${({ theme }) => theme.spacing(0, 2)};
`

export const BottomNavigationAction = styled(MuiBottomNavigationAction)`
  gap: ${({ theme }) => theme.spacing(0.5)};
  padding: ${({ theme }) => theme.spacing(1, 0.5)};

  & .Mui-selected,
  & .active,
  & .MuiBottomNavigationAction-iconOnly {
    font-size: 0 !important;
    padding: unset;
  }
`
