import React from 'react'
import { AuthenticationKind } from 'constants/authentication'
import { FF_FAVUR_REDESIGN } from 'constants/featureFlags'
import useFeatureFlag from 'hooks/useFeatureFlag'
import useModule from 'hooks/useModule'
import useRolesViews from 'hooks/useRolesViews'
import useSessionContext from 'hooks/useSessionContext'
import DashboardPage from 'pages/Dashboard'
import TermsAndConditionsUpdatePage from 'pages/EntryPoint/LoginPage/TermsAndConditionsUpdate'
import AppTeaserPage from 'pages/NativeAppTeaser'
import NotificationsPage from 'pages/Notifications'
import PhoneChangePage from 'pages/PhoneChange'
import PinChangePage from 'pages/SecuritySetup/PinChange'
import PinResetPage from 'pages/SecuritySetup/PinReset'
import TutorialPage from 'pages/Tutorial'
import UserSettingsPage from 'pages/UserSettings'
import DeactivateAccount from 'pages/UserSettings/pages/DeactivateAccount'
import ConfirmDeactivateAccount from 'pages/UserSettings/pages/DeactivateAccount/Confirm'
import EmploymentDataPage from 'pages/UserSettings/pages/EmploymentData'
import ForcePinLogin from 'pages/UserSettings/pages/ForcePinLogin'
import NotificationSettingsPage from 'pages/UserSettings/pages/Notifications'
import PersonalDataPage from 'pages/UserSettings/pages/PersonalData'
import ProfilePage from 'pages/UserSettings/pages/Profile'
import { Redirect } from 'react-router'
import { Route, Switch } from 'react-router-dom'
import NewDashboardPage from 'redesign/pages/Dashboard'
import NewNotificationsPage from 'redesign/pages/Notifications'
import { modules } from 'shared/constants'
import ProtectedRoute from 'utils/ProtectedRoute'
import { isNative } from 'utils/platform'
import {
  absencePlannerRoutes,
  absenceWorkflowRoutes,
  balancesRoutes,
  cmsRoutes,
  documentRoutes,
  employeeCardRoutes,
  employeerRoutes,
  monthlySheetsRoutes,
  notRequiredAuthenticationRoutes,
  officeRoutes,
  secureConnectionRoutes,
  shareUserDataRoutes,
  shiftsRoutes,
  tasksRoutes,
  teamRoutes,
  tenantRoutes,
} from './appRoutes'
import routes from './routes'

interface IGetConditionalRoutesProps {
  hasActivePersons: boolean
  isAuthenticated: boolean
  canManageTeams: boolean
  canManageTenants: boolean
  hasOfficeView: boolean
  cmsActivated: boolean
  hasAccessToModule: (module: string) => boolean
  redesignFF?: boolean
}
interface IGetFeatureRoutesProps {
  isAuthenticated: boolean
  hasAccessToModule: (module: string) => boolean
  redesignFF?: boolean
}

//  Adds routes to the list based on certain user conditions, such as permissions, roles or specific configuration.
//  Only routes accessible to the current user are included.
const getConditionalRoutes = ({
  hasActivePersons,
  isAuthenticated,
  canManageTeams,
  canManageTenants,
  hasOfficeView,
  cmsActivated,
  hasAccessToModule,
  redesignFF,
}: IGetConditionalRoutesProps) => [
  ...(hasActivePersons ? absencePlannerRoutes({ isAuthenticated, hasAccessToModule, redesignFF }) : []),
  ...(cmsActivated ? cmsRoutes({ isAuthenticated, hasAccessToModule }) : []),
  ...(hasOfficeView ? officeRoutes({ isAuthenticated, hasAccessToModule }) : []),
  ...(canManageTeams ? teamRoutes({ isAuthenticated, hasAccessToModule }) : []),
  ...(canManageTenants ? tenantRoutes({ isAuthenticated, hasAccessToModule }) : []),
]

const getFeatureRoutes = ({ isAuthenticated, hasAccessToModule, redesignFF }: IGetFeatureRoutesProps) => [
  ...absenceWorkflowRoutes({ isAuthenticated, hasAccessToModule }),
  ...balancesRoutes({ isAuthenticated, hasAccessToModule }),
  ...documentRoutes({ isAuthenticated, hasAccessToModule, redesignFF }),
  ...employeeCardRoutes({ isAuthenticated, hasAccessToModule }),
  ...employeerRoutes({ isAuthenticated, hasAccessToModule }),
  ...monthlySheetsRoutes({ isAuthenticated, hasAccessToModule }),
  ...secureConnectionRoutes({ isAuthenticated, hasAccessToModule }),
  ...shareUserDataRoutes({ isAuthenticated, hasAccessToModule }),
  ...shiftsRoutes({ isAuthenticated, hasAccessToModule, redesignFF }),
  ...tasksRoutes({ isAuthenticated, hasAccessToModule, redesignFF }),
]

const RoutesProvider: React.FC = () => {
  const { auth, cmsActivated } = useSessionContext()
  const { hasOfficeView, canManageTeams, hasActivePersons, canManageTenants } = useRolesViews()
  const queryParams = new URLSearchParams(location.search)
  const redesignFF = useFeatureFlag(FF_FAVUR_REDESIGN) === true
  const { hasAccessToModule } = useModule()

  if (![AuthenticationKind.AUTHENTICATED, AuthenticationKind.ANONYMOUS].includes(auth)) {
    return <Route component={() => null} />
  }
  const isAuthenticated = auth > AuthenticationKind.ANONYMOUS

  return (
    <Switch>
      <ProtectedRoute
        key={routes.termsAndConditionsUpdate}
        path={routes.termsAndConditionsUpdate}
        // @ts-ignore
        component={TermsAndConditionsUpdatePage}
        exact
        isAllowed={isAuthenticated}
      />
      ,
      <ProtectedRoute
        path={routes.dashboard}
        // @ts-ignore
        component={redesignFF ? NewDashboardPage : DashboardPage}
        exact
        isAllowed={isAuthenticated}
        isRedesignTheme={redesignFF}
      />
      <ProtectedRoute
        key={routes.tutorial}
        path={`${routes.tutorial}/:tutorialName`}
        // @ts-ignore
        component={TutorialPage}
        exact
        isAllowed={isAuthenticated}
      />
      <ProtectedRoute
        path={routes.appTeaser}
        // @ts-ignore
        component={AppTeaserPage}
        exact
        isAllowed={isAuthenticated}
      />
      <ProtectedRoute
        path={routes.userSettings}
        // @ts-ignore
        component={UserSettingsPage}
        exact
        isAllowed={isAuthenticated}
      />
      <ProtectedRoute
        path={routes.profile}
        // @ts-ignore
        component={ProfilePage}
        exact
        isAllowed={isAuthenticated}
      />
      <ProtectedRoute
        path={routes.profileForcePinLogin}
        // @ts-ignore
        component={ForcePinLogin}
        exact
        isAllowed={isAuthenticated}
      />
      <ProtectedRoute
        path={routes.profileDeactivateAccount}
        // @ts-ignore
        component={DeactivateAccount}
        exact
        isAllowed={isAuthenticated}
      />
      <ProtectedRoute
        path={routes.profileConfirmDeactivateAccount}
        // @ts-ignore
        component={ConfirmDeactivateAccount}
        exact
        isAllowed={isAuthenticated}
      />
      {hasAccessToModule(modules.workflowShareUserData) ? (
        <ProtectedRoute
          path={`${routes.employmentData}/:tab?`}
          // @ts-ignore
          component={EmploymentDataPage}
          exact
          isAllowed={isAuthenticated}
        />
      ) : (
        <ProtectedRoute
          path={routes.employmentData}
          // @ts-ignore
          component={PersonalDataPage}
          exact
          isAllowed={isAuthenticated}
        />
      )}
      {isNative() && (
        <ProtectedRoute
          path={routes.notificationsSettings}
          // @ts-ignore
          component={NotificationSettingsPage}
          exact
          isAllowed={isAuthenticated}
        />
      )}
      <ProtectedRoute
        path={`${routes.phoneChange}/:step?`}
        // @ts-ignore
        component={PhoneChangePage}
        exact
        isAllowed={isAuthenticated}
      />
      <ProtectedRoute
        path={routes.notifications}
        // @ts-ignore
        component={redesignFF ? NewNotificationsPage : NotificationsPage}
        exact
        isAllowed={isAuthenticated}
        isRedesignTheme={redesignFF}
      />
      <ProtectedRoute
        path={`${routes.pinReset}/:step?`}
        // @ts-ignore
        component={PinResetPage}
        exact
        isAllowed={isAuthenticated}
      />
      <ProtectedRoute
        path={`${routes.pinChange}/:step?`}
        // @ts-ignore
        component={PinChangePage}
        exact
        isAllowed={isAuthenticated}
      />
      {getFeatureRoutes({ isAuthenticated, hasAccessToModule, redesignFF })}
      {getConditionalRoutes({
        hasActivePersons,
        isAuthenticated,
        canManageTeams,
        canManageTenants,
        hasOfficeView,
        cmsActivated,
        hasAccessToModule,
        redesignFF,
      })}
      {notRequiredAuthenticationRoutes(isAuthenticated, queryParams)}
      {auth < AuthenticationKind.AUTHENTICATED ? (
        <Redirect from="*" to={routes.login} />
      ) : (
        <Redirect from="*" to={routes.dashboard} />
      )}
    </Switch>
  )
}

export default RoutesProvider
