import React from 'react'
import { Button } from '@mui/material'
import CloseButton from 'redesign/components/atoms/Buttons/CloseButton/CloseButton'
import FramedIcon from 'redesign/components/atoms/FramedIcon'
import { PlaceholderIcon } from 'redesign/components/atoms/Icon'
import HeaderLabel from 'redesign/components/molecules/HeaderLabel'
import ContentCard from '../../../organisms/ContentCard'
import { FooterContainer } from '../../../organisms/FullScreenModal/styles'
import Header from '../../../organisms/Header'
import { MenuPageProps } from '../../SideMenu/types'
import { getMainMenuTitle } from '../../SideMenu/utils'

const MainMenu = ({ onClose, pages, parentPage, setPage, t }: MenuPageProps) => (
  <>
    <Header
      middleContent={
        <HeaderLabel mainText={t('navigation.sideMenu.header')} subText={getMainMenuTitle(parentPage, t)} />
      }
      endContent={<CloseButton onClick={onClose} />}
    />
    <div>
      {pages.map((page) => (
        <ContentCard
          key={page}
          header={{
            // TODO: Change placeholder icon once we have icons for different pages
            icon: <FramedIcon icon={PlaceholderIcon} />,
            title: page,
            showChevron: true,
          }}
          onClick={() => setPage(page)}
        />
      ))}
    </div>
    <FooterContainer extraBottomPadding>
      <Button variant="contained" fullWidth onClick={() => setPage('newAbsenceRequest')}>
        {t('absencePlanner.taskbar.newAbsenceRequest.header')}
      </Button>
    </FooterContainer>
  </>
)

export default MainMenu
