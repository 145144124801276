import React from 'react'
import { StyledDrawer } from './styles'
import type { FullPageDrawerProps } from './types'

const FullPageDrawer: React.FC<FullPageDrawerProps> = ({ onClose, open, children }) => (
  <StyledDrawer anchor="right" open={open} onClose={onClose}>
    {children}
  </StyledDrawer>
)

export default FullPageDrawer
