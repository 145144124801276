import React from 'react'
import { ThemeProvider } from '@mui/material/styles'
import FullPageDrawer from 'redesign/components/atoms/FullPageDrawer'
import SideMenuProvider from 'redesign/services/SideMenu/SideMenuProvider'
import theme from 'redesign/themes/main'
import SideMenuContent from '../../organisms/SideMenuContent/SideMenuContent'
import type { SideMenuProps } from './types'

const SideMenu: React.FC<SideMenuProps> = (props) => (
  <ThemeProvider theme={theme}>
    <SideMenuProvider {...props}>
      <FullPageDrawer {...props}>
        <SideMenuContent />
      </FullPageDrawer>
    </SideMenuProvider>
  </ThemeProvider>
)

export default SideMenu
