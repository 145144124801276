import type { TFunction } from 'react-i18next'
import { ABSENCE_PLAN_CONFIGURATION, PARENT_PAGES, SHIFT_PLAN_CONFIGURATION } from './constants'
import type { ParentPage } from './types'

export const getMainMenuTitle = (parentPage: ParentPage, t: TFunction<'translation', undefined>): string => {
  switch (parentPage) {
    case PARENT_PAGES.shiftPlan:
      return t('page.shifts.content.shiftsTitle')
    case PARENT_PAGES.absencePlan:
      return t('page.absencePlanner.title')
    default:
      return ''
  }
}

export const getSideMenuConfiguration = (parentPage: ParentPage) => {
  switch (parentPage) {
    case PARENT_PAGES.shiftPlan:
      return SHIFT_PLAN_CONFIGURATION
    case PARENT_PAGES.absencePlan:
      return ABSENCE_PLAN_CONFIGURATION
    default:
      return []
  }
}
