import React from 'react'
import { Typography, useTheme } from '@mui/material'
import ShiftStack from 'redesign/components/atoms/ShiftStacks/ShiftStacks'
import { AVATAR_SIZE } from './constants'
import { Container, MuiAvatar, MuiBadge } from './styles'
import type { AvatarProps } from './types'

const Avatar: React.FC<AvatarProps> = ({
  size = AVATAR_SIZE.extraLarge,
  src,
  badgeContent,
  badgeVariant = 'standard',
  text,
  icon,
  shifts,
  removeBackground = false,
  testId,
}) => {
  const { palette } = useTheme()
  const variant = size > AVATAR_SIZE.medium ? badgeVariant : 'dot'
  const typographyVariant = size > AVATAR_SIZE.medium ? 'body1' : 'caption'
  const content = icon ?? (
    <Typography color={removeBackground ? palette.text.secondary : palette.common.white} variant={typographyVariant}>
      {text}
    </Typography>
  )
  const shiftsStacks = shifts && <ShiftStack shifts={shifts} />

  return (
    <MuiBadge
      data-testid={testId}
      size={size}
      anchorOrigin={{
        vertical: 'bottom',
      }}
      color="primary"
      variant={variant}
      badgeContent={badgeContent}
    >
      <Container>
        <MuiAvatar removeBackground={removeBackground} alt={text} size={size} src={src}>
          {content}
        </MuiAvatar>
        {shiftsStacks}
      </Container>
    </MuiBadge>
  )
}

export default Avatar
