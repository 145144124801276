const palette = {
  text: {
    primary: '#141A33DE',
    secondary: '#141A3399',
    disabled: '#141A337A',
  },
  primary: {
    dark: '#93005A',
    main: '#FF0080',
    light: '#FFE0EA',
    hover: '#FF00800A',
    selected: '#FF008014',
    focus: '#FF00801F',
    focusVisible: '#FF00804D',
    outlinedBorder: '#FF008080',
  },
  secondary: {
    dark: '#122DB0',
    main: '#3D55CC',
    light: '#F3F2FF',
    selected: '#3D55CC14',
  },
  error: {
    dark: '#C62828',
    main: '#F44336',
    light: '#EF5350',
  },
  warning: {
    dark: '#E65100',
    main: '#EF6C00',
    light: '#FF9800',
  },
  info: {
    dark: '#01579B',
    main: '#0288D1',
    light: '#03A9F4',
  },
  success: {
    dark: '#1B5E20',
    main: '#2E7D32',
    light: '#4CAF50',
  },
  grey: {
    500: '#5D6688',
    600: '#414B67',
  },
  common: {
    backgroundApp: '#F5F6FA',
    backgroundCard: '#FFFFFF',
    backgroundSideMenu: '#0f2d79',
    cardBorder: '#141A331F',
    darkerGrey: '#141A33DE',
    greyDark: '#28324D',
    greyMid: '#878CA8',
    greyLight: '#F5F6FA',
    hamburgerHeader: '#082150',
    hoverCard: '#141A330A',
    hoverNavItem: '#760BFF33',
    navItemBase: '#C9C8FE',
    newCard: '#FF00800A',
    transparent: 'transparent',
    white: '#FFFFFF',
  },
}

export default palette
