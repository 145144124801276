import React, { useEffect } from 'react'
import LoadingSpinner from 'redesign/components/atoms/LoadingSpinner'
import PullToRefreshContentWrapper from 'redesign/components/molecules/PullToRefreshContentWrapper'
import ShiftPlanMonthlyCalendar from 'redesign/components/organisms/ShiftPlanMonthlyCalendar'
import { useSwipeableView } from 'redesign/services/SwipeableView/useSwipeableView'
import { useApolloClient } from '@apollo/client'
import ShiftPlanData from './ShiftPlanData'
import { QUERIES_TO_REFETCH } from './constants'
import { SwipeableContainer, SwipeableViews } from './styles'
import type { SwipeableShiftPlanProps } from './types'

const ShiftPlanTemplate: React.FC<SwipeableShiftPlanProps> = ({ isNative }) => {
  const { finishLoadingGoToDay, state, handleIndexChange, loading, toggleCalendarOpen, goToDay } = useSwipeableView()
  const { index, calendarOpen, animateTransition } = state
  const client = useApolloClient()
  const onRefresh = async () =>
    client.refetchQueries({
      include: QUERIES_TO_REFETCH,
    })

  useEffect(() => {
    if (loading) {
      finishLoadingGoToDay()
    }
  }, [finishLoadingGoToDay, loading])

  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <>
      <ShiftPlanMonthlyCalendar
        onClickDay={(day: Date) => goToDay(day)}
        open={calendarOpen}
        onClose={toggleCalendarOpen}
      />
      <PullToRefreshContentWrapper disabled={!isNative} onRefresh={onRefresh}>
        <SwipeableViews
          animateTransitions={animateTransition}
          enableMouseEvents={isNative}
          index={index}
          onChangeIndex={(newIndex, indexLatest) => {
            handleIndexChange(newIndex < indexLatest ? -1 : 1)
          }}
          overscanSlideAfter={1}
          overscanSlideBefore={1}
          slideRenderer={(sliderParams) => (
            <SwipeableContainer slide={sliderParams.index} index={index} key={sliderParams.index}>
              <ShiftPlanData sliderParams={sliderParams} />
            </SwipeableContainer>
          )}
        />
      </PullToRefreshContentWrapper>
    </>
  )
}

export default ShiftPlanTemplate
