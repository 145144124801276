import React from 'react'
import BackButton from 'redesign/components/atoms/Buttons/BackButton'
import CloseButton from 'redesign/components/atoms/Buttons/CloseButton/CloseButton'
import HeaderLabel from 'redesign/components/molecules/HeaderLabel'
import Header from '../../../organisms/Header'
import { SIDE_MENU_OPTIONS } from '../../SideMenu/constants'
import { MenuPageProps } from '../../SideMenu/types'
import { getMainMenuTitle } from '../../SideMenu/utils'

const Events = ({ onClose, parentPage, t, setPage }: MenuPageProps) => (
  <>
    <Header
      startContent={<BackButton onGoBack={() => setPage(SIDE_MENU_OPTIONS.mainMenu)} />}
      middleContent={
        <HeaderLabel mainText={t('navigation.sideMenu.header')} subText={getMainMenuTitle(parentPage, t)} />
      }
      endContent={<CloseButton onClick={onClose} />}
    />
  </>
)

export default Events
