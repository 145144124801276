import React, { useState } from 'react'
import { useTheme } from '@mui/material'
import { AuthenticationKind } from 'constants/authentication'
import useKeyboardState from 'hooks/useKeyboardState'
import useSessionContext from 'hooks/useSessionContext'
import SafeSpace from 'redesign/components/atoms/SafeSpace'
import HamburgerHeader from 'redesign/components/molecules/HamburgerHeader'
import SideMenuNavigation from 'redesign/components/templates/NavigationSideMenu'
import { convertPxToMuiSpacing } from 'redesign/themes/main/utils'
import { isNative } from 'utils/platform'
import NavigationBar from '../NavigationBar'
// TODO: Apply when redesign is finished for accessibility reasons
// import '@ionic/react/css/core.css'
// import '@ionic/react/css/normalize.css'
// import '@ionic/react/css/typography.css'
import { Content, ContentContainer } from './styles'
import { PageProps } from './types'

const Page: React.FC<PageProps> = ({
  children,
  header,
  backgroundColor,
  footer,
  center = false,
  flex = false,
  paddingX = 0.75,
  noPadding,
  columnCenter,
  fitToViewport,
  growContent = true,
  hasFloatingButtons,
  removeLastMargin,
  fullContentWidth = false,
  fullContentHeight = false,
  extraBottomMargin = 0,
  hideNativeNavigation,
  rootId,
  showHamburgerHeader = !isNative(),
  showWebHamburger = true,
  showHamburgerHeaderLogo = true,
}) => {
  const { isOpen, keyboardHeight } = useKeyboardState()
  const extraBotMargin = (isOpen ? convertPxToMuiSpacing(keyboardHeight) : 0) + extraBottomMargin
  const theme = useTheme()
  const { auth } = useSessionContext()
  const [navigate, setNavigation] = useState(false)
  const showSidePanel = () => setNavigation(true)
  const hideSidePanel = () => setNavigation(false)
  const toggleDrawer = () => setNavigation(!navigate)
  const hasNavbar = auth === AuthenticationKind.AUTHENTICATED && !hideNativeNavigation
  const displayHamburgerHeader = auth === AuthenticationKind.AUTHENTICATED && showHamburgerHeader

  return (
    <>
      <SideMenuNavigation
        toggleDrawer={toggleDrawer}
        navigate={navigate}
        auth={auth}
        showHamburgerHeader={displayHamburgerHeader}
      />
      <SafeSpace rootId={rootId} hasNavbar={hasNavbar}>
        {displayHamburgerHeader && (
          <HamburgerHeader
            toggleMenu={toggleDrawer}
            showHamburger={showWebHamburger}
            showLogo={showHamburgerHeaderLogo}
          />
        )}
        {header}
        <ContentContainer
          no-bounce
          has-bouncing="false"
          forceOverscroll={false}
          id="ionContent"
          backgroundColor={backgroundColor ?? theme.palette.common.backgroundApp}
        >
          <Content
            isFlex={flex}
            paddingX={paddingX}
            center={center}
            growContent={growContent}
            footer={Boolean(footer)}
            columnCenter={columnCenter}
            fitToViewport={fitToViewport}
            fullContentHeight={fullContentHeight}
            isOpen={isOpen}
            fullContentWidth={fullContentWidth}
            removeLastMargin={removeLastMargin}
            hasFloatingButtons={hasFloatingButtons}
            addSafeMargin={!header}
            noPadding={noPadding}
            extraBottomMargin={extraBotMargin}
          >
            {children}
          </Content>
        </ContentContainer>
        {footer}
        {hasNavbar && (
          <NavigationBar isMenuActive={navigate} showSidePanel={showSidePanel} hideSidePanel={hideSidePanel} />
        )}
      </SafeSpace>
    </>
  )
}

export default Page
