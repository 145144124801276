import React from 'react'
import ShiftPlanHeader from 'redesign/components/organisms/Headers/ShiftPlanHeader'
import Page from 'redesign/components/templates/Page'
import ShiftPlanTemplate from 'redesign/components/templates/ShiftPlanContent/ShiftPlanTemplate'
import SwipeableViewProvider from 'redesign/services/SwipeableView/SwipeableViewProvider'
import { isNative } from 'utils/platform'
import { SHIFT_PLAN_PAGE_ROOT_ID } from './constants'

const ShiftPlanPage: React.FC = () => {
  const isNativeView = isNative()

  return (
    <SwipeableViewProvider>
      <Page header={<ShiftPlanHeader />} paddingX={0} removeLastMargin rootId={SHIFT_PLAN_PAGE_ROOT_ID}>
        <ShiftPlanTemplate isNative={isNativeView} />
      </Page>
    </SwipeableViewProvider>
  )
}

export default ShiftPlanPage
