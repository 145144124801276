import React from 'react'
import Headline from 'redesign/components/atoms/Headline'
import LoadingSpinner from 'redesign/components/atoms/LoadingSpinner'
import TeamCard from '../../TeamCard'
import { CardsContainer, Container } from '../styles'
import type { TeamSectionProps } from './types'
import { useTeamSection } from './useTeamSection'

const TeamSection: React.FC<TeamSectionProps> = ({ teamPlan, teamPlanLoading, onTeamClick }) => {
  const { t, personIds, attendances, vacationAbsences, teamsToDisplay } = useTeamSection({ teamPlan })

  if (teamPlanLoading) {
    return <LoadingSpinner />
  }

  if (!teamsToDisplay.length) {
    return null
  }

  return (
    <Container>
      <Headline text={t('shiftPlan.headline.teamSection')} testId="my-teams-headline" />
      <CardsContainer>
        {teamsToDisplay.map((teamPlanPersons) => {
          const { hideAbsences, hideAttendances, title, id, tenantId, showTeamplanCostCenters } = teamPlanPersons
          const teamAttendances =
            attendances[id]?.filter((attendance) => !personIds?.includes(attendance.personId)) ?? []
          const teamAbsences =
            vacationAbsences[teamPlanPersons.id]?.filter((absence) => !personIds?.includes(absence.personId)) ?? []

          return (
            <TeamCard
              hideAbsences={hideAbsences}
              hideAttendances={hideAttendances}
              key={id}
              attendances={teamAttendances}
              absences={teamAbsences}
              title={title}
              personIds={personIds}
              onCardClick={() =>
                onTeamClick({
                  teamId: id,
                  tenantId,
                  teamName: title,
                  showCostCenter: showTeamplanCostCenters,
                })
              }
            />
          )
        })}
      </CardsContainer>
    </Container>
  )
}

export default TeamSection
