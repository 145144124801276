import type { MenuOptions } from './types'

export const PARENT_PAGES = {
  shiftPlan: 'shiftPlan',
  absencePlan: 'absencePlan',
} as const

export const SIDE_MENU_OPTIONS = {
  mainMenu: 'mainMenu',
  events: 'events',
  personalAbsences: 'personalAbsences',
  adminAbsences: 'adminAbsences',
  personalShiftUpdates: 'personalShiftUpdates',
  adminShiftUpdates: 'adminShiftUpdates',
  settings: 'settings',
  newAbsenceRequest: 'newAbsenceRequest',
} as const

export const SHIFT_PLAN_CONFIGURATION: MenuOptions[] = [
  SIDE_MENU_OPTIONS.events,
  SIDE_MENU_OPTIONS.personalShiftUpdates,
  SIDE_MENU_OPTIONS.settings,
]

export const ADMIN_SHIFT_PLAN_CONFIGURATION: MenuOptions[] = [
  ...SHIFT_PLAN_CONFIGURATION,
  SIDE_MENU_OPTIONS.adminShiftUpdates,
]

export const ABSENCE_PLAN_CONFIGURATION: MenuOptions[] = [
  SIDE_MENU_OPTIONS.events,
  SIDE_MENU_OPTIONS.personalAbsences,
  SIDE_MENU_OPTIONS.settings,
]

export const ADMIN_ABSENCE_PLAN_CONFIGURATION: MenuOptions[] = [
  ...ABSENCE_PLAN_CONFIGURATION,
  SIDE_MENU_OPTIONS.adminAbsences,
]
