import React from 'react'
import { Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import DatePickerDrawer from 'redesign/components/organisms/DatePickerDrawer'
import DrawerHeader from 'redesign/components/organisms/DrawerHeader'
import DatePickerInput from '../../atoms/DatePickerInput'
import type { DatePickerProps } from './types'
import { useDatePicker } from './useDatePicker'

const DatePicker: React.FC<DatePickerProps> = ({
  label,
  testId = 'date-picker',
  disabled,
  error,
  fullWidth,
  onChange,
  defaultValue,
  minDate,
  maxDate,
}) => {
  const {
    displayedFieldValue,
    monthOptionProps,
    onChangeValue,
    onClick,
    onClose,
    open,
    yearOptionProps,
  } = useDatePicker({ onChange, defaultValue, minDate, maxDate })
  const { t } = useFavurTranslation()

  return (
    <>
      <DatePickerInput
        disabled={disabled}
        error={error}
        focused={open}
        label={label}
        onClick={onClick}
        testId={testId}
        value={displayedFieldValue}
        fullWidth={fullWidth}
      />
      <DatePickerDrawer
        header={
          <DrawerHeader
            middleContent={<Typography variant="subtitle1">{t('filter.task_time_frame.date_picker_title')}</Typography>}
            closeIconOnClick={onClose}
          />
        }
        monthOptionProps={monthOptionProps}
        onClose={onClose}
        onChange={onChangeValue}
        open={open}
        yearOptionProps={yearOptionProps}
      />
    </>
  )
}
export default DatePicker
