import {
  TEAM_PLAN_WITH_TEAMS_QUERY,
  GET_ABSENCE_REQUEST_QUERY,
  ALL_EFFECTIVE_SHIFTS_FAVUR_QUERY,
  ALL_SHIFTS_FAVUR_QUERY,
  GET_TEAMS_QUERY,
} from 'redesign/shared/queries/constants'

export const QUERIES_TO_REFETCH = [
  TEAM_PLAN_WITH_TEAMS_QUERY,
  GET_ABSENCE_REQUEST_QUERY,
  ALL_EFFECTIVE_SHIFTS_FAVUR_QUERY,
  ALL_SHIFTS_FAVUR_QUERY,
  GET_TEAMS_QUERY,
]
