import React from 'react'
import { AbsencePlannerStateProvider } from 'pages/AbsencePlanner/contexts/AbsencePlannerStateContext'
import { TaskBarStateProvider } from 'pages/AbsencePlanner/contexts/TaskBarStateContext'
import PinLogin from 'pages/PinLogin'
import AbsencePlannerPage from './AbsencePlannerPage'

const AbsencePlannerPageWrappers: React.FC = () => {
  return (
    <PinLogin>
      <TaskBarStateProvider>
        <AbsencePlannerStateProvider>
          <AbsencePlannerPage />
        </AbsencePlannerStateProvider>
      </TaskBarStateProvider>
    </PinLogin>
  )
}

export default AbsencePlannerPageWrappers
