import React, { useMemo } from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import NewAbsenceRequestPage from 'pages/AbsencePlanner/components/TaskBar/AbsenceRequestTab/NewAbsenceRequest/NewAbsenceRequestPage'
import { SIDE_MENU_OPTIONS } from 'redesign/components/templates/SideMenu/constants'
import Events from 'redesign/components/templates/SideMenuPages/Events'
import MainMenu from 'redesign/components/templates/SideMenuPages/MainMenu'
import { useSideMenuContext } from 'redesign/services/SideMenu/useSideMenuContext'

export const useSideMenuContent = () => {
  const { t } = useFavurTranslation()
  const context = useSideMenuContext()
  const { page, setPage } = context
  const props = useMemo(() => ({ ...context, t }), [context, t])

  const PageContent = useMemo(() => {
    switch (page) {
      case SIDE_MENU_OPTIONS.personalAbsences:
        // This component is a placeholder
        return <Events {...props} />
      case SIDE_MENU_OPTIONS.adminAbsences:
        // This component is a placeholder
        return <Events {...props} />
      case SIDE_MENU_OPTIONS.personalShiftUpdates:
        // This component is a placeholder
        return <Events {...props} />
      case SIDE_MENU_OPTIONS.adminShiftUpdates:
        // This component is a placeholder
        return <Events {...props} />
      case SIDE_MENU_OPTIONS.events:
        // This component is a placeholder
        return <Events {...props} />
      case SIDE_MENU_OPTIONS.settings:
        // This component is a placeholder
        return <Events {...props} />
      case SIDE_MENU_OPTIONS.newAbsenceRequest:
        return <NewAbsenceRequestPage refetchAll={() => {}} goBack={() => setPage(SIDE_MENU_OPTIONS.mainMenu)} />
      default:
        return <MainMenu {...props} />
    }
  }, [page, props, setPage])

  return { PageContent }
}
