import React from 'react'
import { findConfigurationElement } from '../ChipList/reducer'
import { getDefaultFilters } from '../context'
import {
  ACTION,
  ActionDispatchT,
  FilterPageStateT,
  FilterType,
  ReducerActionT,
  UpdateConfigurationOptionsActionT,
} from '../types'
import { allCheckboxesSelected } from './CheckList/reducer'
import { checkDateRangeFilter } from './DateRange/reducer'

export const actions = (filterPageState: FilterPageStateT, dispatch: React.Dispatch<ReducerActionT>) => ({
  isFilterPageOpen: () => filterPageState.displayFilterPage,
  openFilterPage: () => {
    dispatch({ type: ACTION.OPEN_FILTER_PAGE })
  },
  closeFilterPage: () => {
    dispatch({ type: ACTION.CLOSE_FILTER_PAGE })
  },
  submitFilterPage: () => {
    dispatch({ type: ACTION.SUBMIT_FILTER_PAGE })
    dispatch({ type: ACTION.UPDATE_CHIPS })
  },
  resetFilterPage: ({ isRedesign = false }) => {
    dispatch({ type: ACTION.RESET_FILTER_PAGE, value: { isRedesign } })
    dispatch({ type: ACTION.UPDATE_CHIPS })
  },
  validateFilters: () => {
    const { configuration, dirtyFilters } = filterPageState
    const reduced = configuration
      .map((confItem) => {
        switch (confItem.type) {
          case FilterType.DATE_RANGE:
            return checkDateRangeFilter(confItem, dirtyFilters)
          default:
            return true
        }
      })
      .reduce((acc, current) => acc && current, true)
    return reduced
  },
})

export const reducers: ActionDispatchT = {
  [ACTION.OPEN_FILTER_PAGE]: (state, _action) => {
    return {
      ...state,
      displayFilterPage: true,
      dirtyFilters: { ...state.filters },
    }
  },
  [ACTION.CLOSE_FILTER_PAGE]: (state, _action) => {
    return {
      ...state,
      dirtyFilters: {},
      displayFilterPage: false,
    }
  },
  [ACTION.SUBMIT_FILTER_PAGE]: (state, _action) => {
    const { configuration, dirtyFilters } = state
    const filteredDirtyFilters = Object.entries(dirtyFilters).reduce((acc, filter) => {
      const [filterName, value] = filter
      const configElement = findConfigurationElement(configuration, filterName)
      if (configElement.type === FilterType.MULTIPLE_CHOICE && allCheckboxesSelected(configElement, value)) return acc
      return { ...acc, [filterName]: value }
    }, {})

    return {
      ...state,
      filters: { ...filteredDirtyFilters },
      dirtyFilters: {},
      displayFilterPage: false,
    }
  },
  [ACTION.RESET_FILTER_PAGE]: (state, _action) => {
    const filters = getDefaultFilters(state.configuration, false)
    const isRedesign = _action?.value && 'isRedesign' in _action.value && _action.value.isRedesign

    return {
      ...state,
      filters,
      dirtyFilters: {},
      displayFilterPage: Boolean(isRedesign),
    }
  },
  [ACTION.UPDATE_CONFIGURATION_OPTIONS]: (state, action) => {
    const actionValue = action.value as UpdateConfigurationOptionsActionT
    const filters = getDefaultFilters(state.configuration, false)

    return {
      ...state,
      configuration: actionValue.options,
      filters,
      dirtyFilters: {},
    }
  },
}
