import React from 'react'
import { Typography, useTheme } from '@mui/material'
import { isSameDay, isToday } from 'date-fns'
import useFavurTranslation from 'hooks/useFavurTranslation'
import DayCell from 'redesign/components/atoms/DayCell'
import ShiftStack from 'redesign/components/atoms/ShiftStacks/ShiftStacks'
import { dateToISOStringWithoutTime, getWeekDayNamedByLanguage, getWeekDays } from 'utils/date'
import { Container, DayContainer, ShiftsContainer } from './styles'
import type { CalendarRowWeekDayProps } from './types'

const CalendarRowWeekDay: React.FC<CalendarRowWeekDayProps> = ({
  selectedDay,
  showTopCalendarShifts = true,
  showDayNumbers = true,
  showSelectedDay = true,
  handleDayClick,
  weeklyShifts,
  canShowShifts,
}) => {
  const { language } = useFavurTranslation()
  const { palette } = useTheme()
  const weekDays = getWeekDays(selectedDay)

  return (
    <Container isCurrentWeek={showSelectedDay}>
      {weekDays.map((day) => {
        const stringDate = dateToISOStringWithoutTime(day)
        const dayShifts = (weeklyShifts?.[stringDate] || []).filter((shift) => canShowShifts(shift))

        return (
          <DayContainer key={stringDate} data-testid={`week-row-day_${stringDate}`}>
            <Typography variant="body2" color={palette.text.secondary}>
              {getWeekDayNamedByLanguage(day, language)}
            </Typography>
            {showDayNumbers && (
              <DayCell
                testId={`week-row-day-number_${stringDate}`}
                isSelected={showSelectedDay && isSameDay(selectedDay, day)}
                isToday={isToday(day)}
                text={`${day.getDate()}`}
                onClick={() => handleDayClick && handleDayClick(day)}
                isButton
              />
            )}
            <ShiftsContainer>
              {showTopCalendarShifts && dayShifts.length > 0 && (
                <ShiftStack testId={`week-row-day-shifts_${stringDate}`} shifts={dayShifts} />
              )}
            </ShiftsContainer>
          </DayContainer>
        )
      })}
    </Container>
  )
}

export default CalendarRowWeekDay
