import React from 'react'
import { PullIndicator, Refresher } from './styles'
import { PullToRefreshContentWrapperProps } from './types'

const PullToRefreshContentWrapper: React.FC<PullToRefreshContentWrapperProps> = ({
  children,
  disabled = false,
  onRefresh,
}) => (
  <Refresher
    slot="fixed"
    disabled={disabled}
    onIonRefresh={async (event) => {
      await onRefresh()
      event.detail.complete()
    }}
  >
    <PullIndicator />
    {children}
  </Refresher>
)

export default PullToRefreshContentWrapper
