import React, { useEffect, useState } from 'react'
import { Box, Button, Container, Fade, Typography } from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'
import { SimpleLoading } from 'components/LoadingIcon'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { MAX_WAIT } from 'utils/retry'
import { styles } from './styles'

const LOADING_TEXT_WAIT_TIME = 3_000 //3 seconds
const MAX_LINEAR_PROGRESS_VALUE = 100
const UPDATE_INTERVAL = 100 //ms

interface AppUnavailablePageProps {
  onRetry?: () => void
  loading?: boolean
}

const AppUnavailablePage: React.FC<AppUnavailablePageProps> = ({ loading = false, onRetry = () => {} }) => {
  const { t } = useFavurTranslation()
  const [showLoadingTextWait, setShowLoadingTextWait] = useState(false)
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const timer = setTimeout(() => setShowLoadingTextWait(true), LOADING_TEXT_WAIT_TIME)

    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    // Every 100ms the progress of the bar is updated
    const step = (MAX_LINEAR_PROGRESS_VALUE * UPDATE_INTERVAL) / MAX_WAIT
    const timer = setInterval(() => {
      setProgress((prev) => {
        const nextValue = prev + step
        return nextValue >= 100 ? 100 : nextValue
      })
    }, UPDATE_INTERVAL)

    return () => clearInterval(timer)
  }, [])

  if (loading) {
    return (
      <Container maxWidth="sm" sx={styles.container}>
        <Fade in={showLoadingTextWait}>
          <Box sx={styles.loadingBox}>
            <Typography variant="body1" gutterBottom sx={styles.text}>
              {t('page.appUnavailable.loadingText')}
            </Typography>
            <LinearProgress variant="determinate" sx={styles.progressBar} value={progress} />
          </Box>
        </Fade>
        <SimpleLoading />
      </Container>
    )
  }

  return (
    <Container maxWidth="sm" sx={styles.container}>
      <Typography variant="h1" gutterBottom sx={styles.text}>
        {t('page.appUnavailable.text1')}
      </Typography>
      <Typography variant="body1" gutterBottom sx={styles.text}>
        {t('page.appUnavailable.text2')}
      </Typography>
      <Box mt={4}>
        <Button variant="contained" color="secondary" onClick={onRetry}>
          {t('page.appUnavailable.button')}
        </Button>
      </Box>
    </Container>
  )
}

export default AppUnavailablePage
